@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --foreground: 0 0% 98%;
        --background: 0 0% 3.9%;

        --card: 0 0% 3.9%;
        --card-foreground: 0 0% 98%;

        --popover: 0 0% 3.9%;
        --popover-foreground: 0 0% 98%;

        --primary: 0 0% 98%;
        --primary-foreground: 0 0% 9%;

        --secondary: 0 0% 14.9%;
        --secondary-foreground: 0 0% 98%;

        --muted: 0 0% 14.9%;
        --muted-foreground: 0 0% 63.9%;

        --accent: 0 0% 14.9%;
        --accent-foreground: 0 0% 98%;

        --destructive: 0 62.8% 30.6%;
        --destructive-foreground: 0 0% 98%;

        --border: 0 0% 14.9%;
        --input: 0 0% 14.9%;
        --ring: 0 0% 83.1%;
        --link: 271 91% 65.1%;

        --radius: 0.5rem;
    }

    .dark {
        --background: 0 0% 100%;
        --foreground: 0 0% 3.9%;

        --card: 0 0% 100%;
        --card-foreground: 0 0% 3.9%;

        --popover: 0 0% 100%;
        --popover-foreground: 0 0% 3.9%;

        --primary: 0 0% 9%;
        --primary-foreground: 0 0% 98%;

        --secondary: 0 0% 96.1%;
        --secondary-foreground: 0 0% 9%;

        --tertiary: 0 0% 90%;
        --tertiary-foreground: 0 0% 9%;

        --subtle: 240 6% 90%;
        --subtle-foreground: 0 0% 9%;

        --muted: 0 0% 96.1%;
        --muted-foreground: 0 0% 45.1%;

        --accent: 0 0% 96.1%;
        --accent-foreground: 0 0% 9%;

        --destructive: 0 84.2% 60.2%;
        --destructive-foreground: 0 0% 98%;

        --border: 0 0% 89.8%;
        --input: 0 0% 89.8%;
        --ring: 0 0% 3.9%;
    }
}

::selection {
    background-color: rgba(168, 85, 247, 0.2);
    color: hsl(var(--foreground));
}

html,
body {
    font-family: "Inter", sans-serif !important;
}

.dumy {
    color: hsl(0 0% 98%);
    color: rgb(38, 38, 38);
    /* @apply to-purple-500 */
}

::-webkit-scrollbar {
    width: 6px;
    scrollbar-color: #262626;
}

::-webkit-scrollbar-thumb {
    background-color: #262626;
    border-radius: 3px;
}

::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
}

.gradient {
    background: conic-gradient(
        from 230.29deg at 51.63% 52.16%,
        rgb(36, 0, 255) 0deg,
        rgb(0, 135, 255) 67.5deg,
        rgb(108, 39, 157) 198.75deg,
        rgb(24, 38, 163) 251.25deg,
        rgb(54, 103, 196) 301.88deg,
        rgb(105, 30, 255) 360deg
    );
}

.not-found {
    box-shadow: rgba(150, 18, 226, 0.3) 5px 5px;
}

@keyframes waves {
    0% {
        -webkit-transform: scale(0.2, 0.2);
        transform: scale(0.2, 0.2);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
    50% {
        opacity: 0.9;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
    }
    100% {
        -webkit-transform: scale(0.9, 0.9);
        transform: scale(0.9, 0.9);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
}

.jltwp-spotlight-waves::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 120px;
    height: 120px;
    border-radius: 100%;
    background-color: #7370ff99;
    animation: waves 3s linear infinite;
}

.jltwp-spotlight-media-modal {
    z-index: 9999999999;
}
.jltwp-spotlight-media-modal::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    opacity: 0.8;
    background-color: #1d1d1d;
}

.jltwp-spotlight-modal-wrapper {
    position: relative;
    width: 960px;
    height: 540px;
    border-radius: 8px;
}

.jltwp-spotlight-modal-wrapper iframe {
    border-radius: 8px;
    user-select: none;
}

.jltwp-spotlight-modal-wrapper button {
    position: absolute;
    top: 0;
    right: -40px;
}
.jltwp-spotlight-modal-wrapper button:hover {
    color: #ff6363;
}

/* feature wrapper */

.jltwp-spotlight-feature-wrapper {
    display: flex;
    margin-top: 120px;
    margin-bottom: 120px;
    padding: 40px 30px;
    gap: 30px;
}

.jltwp-spotlight-features > div:nth-child(odd) {
    flex-direction: row-reverse;
}

.jltwp-spotlight-feature-wrapper > div {
    flex-basis: 50%;
}

.jltwp-spotlight-features-media video {
    width: 100%;
    height: 100%;
    border-radius: 8px;
}

/* Order received page hide header */

body:has(.jlt-spotlight-order-received) main {
    margin: 0;
}

body:has(.jlt-spotlight-order-received) > header {
    display: none;
}
